import React, {Suspense, lazy, Component} from 'react'; //引入自定义组件
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { ConfigProvider } from 'antd';
import intl from 'react-intl-universal';
import zh_CN from 'antd/es/locale/zh_CN';
import en_US from 'antd/es/locale/en_US';
import { emit } from './emit.js'
const locales = {
    'en-US': require('./lang/en-US.json'),
    'zh-CN': require('./lang/zh-CN.json'),
};

const Triooo = lazy(() => import('./pages/triooo/home/home'));
const TrioooIndex  = lazy(() => import('./pages/triooo/index'));

const WisdomIndex  = lazy(() => import('./pages/wisdom/index'));

const Test   = lazy(() => import('./pages/test'));
const Home   = lazy(() => import('./pages/home'));

const Login  = lazy(() => import('./pages/login/login'));
const AdminIndex   = lazy(() => import('./pages/admin/index'));

//所有的路由配置均在 BrowserRouter 内部
//Routes 组件替换 v5 的 Switch 组件, Route 组件必须使用 Routes 嵌套
//使用 Routes 替换曾经的 Switch

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            antdLang: zh_CN,  // 修改antd组件的国际化
        }
    }
    async componentWillMount(){
        const { userStore, history } = this.props;
        emit.on('lang', lang => this.loadLocales(lang)); // 监听语言改变事件
        this.loadLocales(); // 初始化语言
    }
    loadLocales(lang = 'zh-CN') {
        intl.init({
            currentLocale: lang,  // 设置初始语言
            locales,
        }).then(() => {
            this.setState({
                antdLang: lang === 'zh-CN' ? zh_CN : en_US
            });
        });
    }
    render() {
        const { antdLang } = this.state;
        //console.log(antdLang);
        return (
            // <React.StrictMode>
            <ConfigProvider locale={this.state.antdLang}>
                {/* 注册路由 */}
                <BrowserRouter>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Routes>
                            <Route path='/' element={<Test />} />
                            {/* triooo */}
                            <Route path='/triooo/*' element={<TrioooIndex />} ></Route>
                            {/* end triooo */}

                            {/* wisdom */}
                            <Route path='/wisdom/*' element={<WisdomIndex />} ></Route>
                            {/* end wisdom */}

                            {/* admin */}
                            <Route path='/login' element={<Login />} />
                            <Route path='/admin/*' element={<AdminIndex />} ></Route>
                            {/* end admin */}

                            {/* 重定向到首页 */}
                            <Route path="*" element={<Navigate to="/"/>} />
                            {/* 或者跳转到 NotFound */}
                            {/* <Route path="*" element={<NotFound />} /> */}
                        </Routes>
                    </Suspense>
                </BrowserRouter>
            </ConfigProvider>
        )
    }
}

ReactDOM.render(<App />, document.getElementById('root'));

// ReactDOM.render(
//     // <React.StrictMode>
//     <ConfigProvider locale={this.state.antdLang}>
//         {/* 注册路由 */}
//         <BrowserRouter>
//             <Suspense fallback={<div>Loading...</div>}>
//                 <Routes>
//                     <Route path='/' element={<Triooo />} />
//
//                     {/* triooo */}
//                     <Route path='/triooo/*' element={<TrioooIndex />} ></Route>
//                     {/* end triooo */}
//
//                     {/* wisdom */}
//                     <Route path='/wisdom/*' element={<WisdomIndex />} ></Route>
//                     {/* end wisdom */}
//
//                     {/* admin */}
//                     <Route path='/login' element={<Login />} />
//                     <Route path='/admin/*' element={<AdminIndex />} ></Route>
//                     {/* end admin */}
//
//                     {/* 重定向到首页 */}
//                     <Route path="*" element={<Navigate to="/"/>} />
//                     {/* 或者跳转到 NotFound */}
//                     {/* <Route path="*" element={<NotFound />} /> */}
//                 </Routes>
//             </Suspense>
//         </BrowserRouter>
//     </ConfigProvider>,
//     document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
